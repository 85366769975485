var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',[_c('validation-observer',{ref:"AgentEditForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.editAgent($event)}}},[_c('b-form-group',{attrs:{"label":"Scanner Name","label-for":"Scanner Name"}},[_c('validation-provider',{attrs:{"name":"agent_name","rules":{ regex: /^[a-zA-Z0-9\s\@\_\-]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"agent_name","state":errors.length > 0 ? false : null,"name":"agent_name"},model:{value:(_vm.agent_name),callback:function ($$v) {_vm.agent_name=$$v},expression:"agent_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Scanner End Point","label-for":"Scanner End Point"}},[_c('validation-provider',{attrs:{"name":"agent_end_point","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"agent_end_point","state":errors.length > 0 ? false : null,"name":"agent_end_point"},model:{value:(_vm.agent_end_point),callback:function ($$v) {_vm.agent_end_point=$$v},expression:"agent_end_point"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Scanner Type","label-for":"Scanner Type"}},[_c('validation-provider',{attrs:{"name":"agent_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.agent_types},model:{value:(_vm.agent_type),callback:function ($$v) {_vm.agent_type=$$v},expression:"agent_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Type","label-for":"Type"}},[_c('validation-provider',{attrs:{"name":"asset_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.asset_type_options,"state":errors.length > 0 ? false : null},on:{"change":_vm.onTypeChange},model:{value:(_vm.asset_type_id),callback:function ($$v) {_vm.asset_type_id=$$v},expression:"asset_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Agent Group","label-for":"Agent Group"}},[_c('validation-provider',{attrs:{"name":"agent_group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.agentGroupTypes,"state":errors.length > 0 ? false : null},model:{value:(_vm.agent_group_type),callback:function ($$v) {_vm.agent_group_type=$$v},expression:"agent_group_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.agent_group_type === 'internal')?_c('b-form-group',{attrs:{"label":"Organization","label-for":"Organization"}},[_c('b-form-select',{attrs:{"options":_vm.organizations},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=$$v},expression:"organization"}})],1):_vm._e(),(_vm.agent_group_type === 'internal')?_c('b-form-group',{attrs:{"label":"Network Location"}},[_c('v-select',{attrs:{"label":"text","placeholder":"--Select Network Location--","options":_vm.networkLocationOptions,"autocomplete":"","reduce":function (networkLocation) { return networkLocation.value; },"clearable":false},model:{value:(_vm.networkLocation),callback:function ($$v) {_vm.networkLocation=$$v},expression:"networkLocation"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Scanner Description","label-for":"Scanner Description"}},[_c('b-form-textarea',{attrs:{"id":"agent_description","name":"agent_description","rows":"3","max-rows":"3"},model:{value:(_vm.agent_description),callback:function ($$v) {_vm.agent_description=$$v},expression:"agent_description"}})],1),_c('b-form-group',{attrs:{"label":"Scanner Meta Input","label-for":"Default Meta Input"}},[_c('validation-provider',{attrs:{"name":"agent_meta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"agent_meta","state":errors.length > 0 ? false : null},model:{value:(_vm.agent_meta),callback:function ($$v) {_vm.agent_meta=$$v},expression:"agent_meta"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"primary","disabled":invalid,"size":"sm"}},[_vm._v(" Update ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }